<template>
    <div> {{firmware.libelle}} <span v-if="firmware.recommanded">[Recommandé] </span> </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    props: {
        object: { type: Object, require: true }
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            firmwares: 'host/firmwares'
        }),
        firmware(){
            return [...this.firmwares].find(item => item.uid === this.object.firmware)
        }
    }
}
</script>